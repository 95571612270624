import axios from "axios";
import { ApiFunction, getToken } from ".";

const baseUrl = `${process.env.API_URL_NFT_SOLUTION}/api/v1/payments`;

const axiosInstance = axios.create({
  baseURL: baseUrl || "",
});

export interface NFTPayStationProps {
  invoice?: PayStationInvoice;
  mint_order?: MintInformation;
  merchant_id: number;
}

export interface MintInformation {
  blockchain: string;
  wallet_id: string;
  fiat: string;
  crypto_fiat_rate: number;
  amount: number;
  item_id: number;
}

export interface PayStationInvoice {
  user: PayStationUser;
  settings: PayStationSettings;
  purchase?: PayStationPurchase;
  custom_parameters?: Partial<PayStationCustomParameters>;
}

export interface PayStationPurchase {
  checkout: {
    amount: number;
    currency: string;
  };
  description: {
    value: string;
    items: {
      name: string;
      image_url?: string;
      description: string;
      price: {
        amount: string;
      };
      quantity: number;
    }[];
  };
}

export interface PayStationCustomParameters {
  registration_date: string;
  total_hours: number;
  total_characters: number;
  social_networks_added: boolean;
  profile_image_added: boolean;
  active_date: string;
  total_friends: number;
  additional_verification: boolean;
  win_rate: number;
  last_change_password_date: string;
  chat_activity: boolean;
  forum_activity: boolean;
  total_bans: number;
  profile_completed: boolean;
  notifications_enabled: boolean;
  user_level: number;
  karma_points: number;
  total_sum: number;
  non_premium_currency: number;
  total_game_events: number;
  total_gifts: number;
  tutorial_completed: boolean;
  completed_tasks: number;
  items_used: boolean;
  pvp_activity: boolean;
  total_clans: number;
  unlocked_achievements: number;
  total_inventory_value: number;
  character_customized: boolean;
  session_time: string;
}

export interface PayStationSettings {
  project_id: number;
  external_id?: string;
  language?: string;
  return_url?: string;
  redirect_policy?: {
    redirect_conditions?: PaymentCondition;
    delay?: number;
    autoredirect_from_status_page?: boolean;
    status_for_manual_redirection?: PaymentCondition;
    manual_redirection_action?: "redirect" | "postmessage";
    redirect_button_caption?: string;
  };
  currency?: string;
  mode?: "sandbox";
  payment_method?: number;
  payment_widget?: "paybycash" | "giftcard";
  ui?: {
    theme?: "default" | "default_dark";
    size?: "small" | "medium" | "large";
    version?: "mobile" | "desktop";
    desktop?: {
      header?: {
        is_visible?: boolean;
        visible_logo?: boolean;
        visible_name?: boolean;
        visible_purchase?: boolean;
        type?: "compact" | "normal";
        close_button?: boolean;
      };
      subscription_list?: {
        layout?: "list" | "grid";
        description?: string;
        display_local_price?: boolean;
      };
      virtual_item_list?: {
        layout?: "list" | "grid";
        button_with_price?: boolean;
        view?: "horizontal_navigation" | "vertical_navigation";
      };
      virtual_currency_list?: {
        description?: string;
        button_with_price?: boolean;
      };
    };
    header?: {
      visible_virtual_currency_balance?: boolean;
    };
    mobile?: {
      mode?: "saved_accounts";
      header?: {
        close_button?: boolean;
      };
      footer?: {
        is_visible: boolean;
      };
    };
    license_url?: string;
    components?: {
      virtual_items?: {
        order?: number;
        hidden?: boolean;
        selected_group?: string;
        selected_item?: string;
      };
      virtual_currency?: {
        custom_amount?: boolean;
        order?: number;
        hidden?: boolean;
      };
      subscriptions?: {
        order?: number;
        hidden?: boolean;
      };
    };
    mode?: "user_account";
    is_prevent_external_link_open?: boolean;
    user_account?: {
      info?: {
        order?: number;
        enable?: boolean;
      };
      history?: {
        order?: number;
        enable?: boolean;
      };
      payment_accounts?: {
        order?: number;
        enable?: boolean;
      };
      subscriptions?: {
        order?: number;
        enable?: boolean;
      };
    };
  };
}

/**
 * @description The event in Paystation Widget
 * 1. `init` — Event on widget initialization
 * 2. `open` — Event on opening of the widget
 * 3. `load` — Event after payment interface (PayStation) was loaded
 * 4. `close` — Event after payment interface (PayStation) was closed
 * 5. `status` — Event when the user was moved on the status page
 * 6. `status-invoice` — Event when the user was moved on the status page, but the payment is not yet completed
 * 7. `status-delivering` — Event when the user was moved on the status page, payment was completed, and we’re sending payment notification
 * 8. `status-done` — Event when the user was moved on the status page, and the payment was completed successfully
 * 9. `status-troubled` — Event when the user was moved on the status page, but the payment failed
 * 10. `user-country` — Event when PayStation have determine user country
 */

export const enum PaystationStatus {
  INIT = "init",
  OPEN = "open",
  LOAD = "load",
  CLOSE = "close",
  STATUS = "status",
  STATUS_INVOICE = "status-invoice",
  STATUS_DELIVERING = "status-delivering",
  STATUS_DONE = "status-done",
  STATUS_TROUBLED = "status-troubled",
  USER_COUNTRY = "user-country",
}

export type PaymentCondition =
  | "none"
  | "successful"
  | "successful_or_cancelled"
  | "any";

export interface PayStationUser {
  id: { value: string };
  email?: { value: string };
  name?: { value: string };
  age?: number;
  phone?: { value: string };
  country?: { value: string; allowModify?: boolean };
  attributes?: Record<string, unknown>;
  is_legal?: boolean;
  legal?: {
    name: string;
    address: string;
    vat_id: string;
    country: string;
  };
}

export interface PaymentCompleted {
  order_id: number;
  paystation_invoice_id: number;
}

export const getPaymentToken: ApiFunction<
  NFTPayStationProps,
  { token: string; order_id: number, code: number }
> = async (paymentBody) => {
  const path = `/token`;
  const token = getToken();

  const response = await axiosInstance.post(path, paymentBody, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.data;
};

export const postPaymentCompleted: ApiFunction<PaymentCompleted, void> = async (
  payload
) => {
  const path = `/completed`;
  const token = getToken();
  return await axiosInstance.post(path, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const orderApi = {
  getPaymentToken,
  postPaymentCompleted,
};
