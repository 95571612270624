export enum Blockchain {
  ETH = "Ethereum",
  MATIC = "Polygon",
  SOL = "Solana",
  KLAY = "Klaytn",
  FLOW = "Flow",
  XTZ = "Tezos",
  BSC = "Binance"
}

export enum BlockchainSymbol {
  ETH = "ETH",
  MATIC = "MATIC",
  SOL = "SOL",
  KLAY = "KLAY",
  FLOW = "FLOW",
  XTZ = "XTZ",
  BSC = "BSC"
}

export enum BlockchainStatus {
  ACTIVE = 1,
  DISABLED = 0,
}

const ETHEREUM_MAINNET = 1;
const ETHEREUM_ROPSTEN = 3;
const ETHEREUM_RINKEBY = 4;
const ETHEREUM_GOERLI = 5;
const ETHEREUM_KOVAN = 42;
const POLYGON_MAINNET = 137;
const POLYGON_MUMBAI = 80001;

export interface BlockchainItem {
  id: string;
  name: Blockchain;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  status: BlockchainStatus;
  isHidden: boolean;
  isEVMCompatible: boolean;
  symbol: string;
  brandColor: string;
}

type GetBlockchains = (filter?: {
  status: BlockchainStatus;
}) => BlockchainItem[];

type GetBlockchainById = (id: string) => BlockchainItem;

type GetBlockchainByChainId = (chainId: number) => Blockchain;

const blockchainList: BlockchainItem[] = [
  {
    id: Blockchain.ETH,
    name: Blockchain.ETH,
    symbol: BlockchainSymbol.ETH,
    icon: `/images/logo/ethereum.svg`,
    status: BlockchainStatus.ACTIVE,
    isHidden: false,
    isEVMCompatible: true,
    brandColor: "",
  },
  {
    id: Blockchain.MATIC,
    name: Blockchain.MATIC,
    symbol: BlockchainSymbol.MATIC,
    icon: `/images/logo/polygon.svg`,
    status: BlockchainStatus.ACTIVE,
    isHidden: false,
    isEVMCompatible: true,
    brandColor: "",
  },
  {
    id: Blockchain.BSC,
    name: Blockchain.BSC,
    symbol: BlockchainSymbol.BSC,
    icon: `/images/logo/binance.svg`,
    status: BlockchainStatus.ACTIVE,
    isHidden: false,
    isEVMCompatible: true,
    brandColor: "",
  },
  {
    id: Blockchain.SOL,
    name: Blockchain.SOL,
    symbol: BlockchainSymbol.SOL,
    icon: `/images/logo/solana.svg`,
    status: BlockchainStatus.ACTIVE,
    isHidden: false,
    isEVMCompatible: false,
    brandColor: "",
  },
  {
    id: Blockchain.KLAY,
    name: Blockchain.KLAY,
    symbol: BlockchainSymbol.KLAY,
    icon: `/images/logo/klaytn.svg`,
    status: BlockchainStatus.DISABLED,
    isHidden: true,
    isEVMCompatible: true,
    brandColor: "",
  },
  {
    id: Blockchain.FLOW,
    name: Blockchain.FLOW,
    symbol: BlockchainSymbol.FLOW,
    icon: `/images/logo/flow.svg`,
    status: BlockchainStatus.DISABLED,
    isHidden: true,
    isEVMCompatible: false,
    brandColor: "",
  },
  {
    id: Blockchain.XTZ,
    name: Blockchain.XTZ,
    symbol: BlockchainSymbol.XTZ,
    icon: `/images/logo/tezos.svg`,
    status: BlockchainStatus.DISABLED,
    isHidden: true,
    isEVMCompatible: false,
    brandColor: "",
  },
];

export const getBlockchains: GetBlockchains = (filter) => {
  if (filter) {
    return blockchainList.filter(
      (item) => item.status === filter.status && item.isHidden === false
    );
  }
  return blockchainList.filter((blockchain) => blockchain.isHidden === false);
};

export const getBlockchainById: GetBlockchainById = (id) => {
  const result = blockchainList.find((blockchainItem: BlockchainItem) => {
    return blockchainItem.id === id;
  });

  if (!result) {
    throw new Error(`Blockchain with id ${id} not found`);
  }

  return result;
};

export const getBlockchainByChainId: GetBlockchainByChainId = (chainId) => {
  switch (chainId) {
    case ETHEREUM_MAINNET:
    case ETHEREUM_ROPSTEN:
    case ETHEREUM_RINKEBY:
    case ETHEREUM_GOERLI:
    case ETHEREUM_KOVAN:
      return Blockchain.ETH;
    case POLYGON_MAINNET:
    case POLYGON_MUMBAI:
      return Blockchain.MATIC;
    default:
      throw new Error(`Blockchain with chainId ${chainId} not found`);
  }
};
