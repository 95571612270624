import { useRouter } from "next/router";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { type JWTDecodedResult, jwtDecoded } from "utils/jwtDecoded";
import { authApi } from "../api";
import { babkaLoginClientId } from "../constants/babka";

type SaveToken = (token: string) => void;
type UseAuth = {
  protectRouteGuard: () => void;
  isTokenExpired: () => boolean;
  generateJWT: () => void;
  saveToken: SaveToken;
  isAuthenticated: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userInfo: Record<string, any> | undefined;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const authContext = createContext({} as UseAuth);
export const useAuth = (): UseAuth => useContext(authContext);
export const tokenLocalStorage = "token";

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<JWTDecodedResult>();
  const router = useRouter();

  /**
   * @description This function should be used inside `useEffect` only
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const protectRouteGuard = () => {
    if (!isAuthenticated) {
      router.push("/");
    }
  };

  const isTokenExpired = useCallback(() => {
    const token = localStorage.getItem(tokenLocalStorage);
    if (token) {
      const authInfo = jwtDecoded(token);
      if (authInfo && authInfo.exp * 1000 > Date.now()) {
        setUserInfo(authInfo);
        setIsAuthenticated(true);
        return false;
      }
    }
    setIsAuthenticated(false);
    localStorage.removeItem(tokenLocalStorage);
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, router]);

  useEffect(() => {
    const isLogin = isTokenExpired();
    setIsAuthenticated(!isLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const resetAuth = useCallback(() => {
    try {
      localStorage.removeItem(tokenLocalStorage);
      setIsAuthenticated(false);
    } catch (error: unknown) {
      if (typeof error === "string") {
        throw new Error(error);
      } else if (error instanceof Error) {
        throw error;
      } else {
        throw new Error("Error in resetting auth");
      }
    }
  }, []);

  const saveToken: SaveToken = useCallback((newToken) => {
    localStorage.setItem(tokenLocalStorage, newToken);
    const userInfo = getUserInfo(newToken);

    if (!userInfo) {
      return resetAuth();
    }

    setUserInfo(userInfo);
  }, []);

  const getUserInfo = (token: string) => {
    if (!token) {
      throw new Error("No token");
    }

    const decodedInfo = jwtDecoded(token);

    if (!decodedInfo) {
      return null;
    }
    return decodedInfo;
  };

  const generateJWT = useCallback(async () => {
    const { merchant_id, project_id, item_sku, item_id } = router.query;
    const isAllParamsPresent =
      merchant_id &&
      merchant_id !== "undefined" &&
      project_id &&
      project_id !== "undefined" &&
      item_sku &&
      item_sku !== "undefined" &&
      item_id &&
      item_id !== "undefined";

    if (isAllParamsPresent) {
      try {
        const { code } = router.query;
        const response = await authApi.generateJWT({
          grant_type: "authorization_code",
          client_id: babkaLoginClientId.toString(),
          redirect_uri: `${location.protocol}//${location.host}`,
          code: code as string,
        });
        localStorage.setItem(tokenLocalStorage, response.access_token);
        const decodedInfo = jwtDecoded(response.access_token);
        if (decodedInfo) {
          setUserInfo(decodedInfo);
        }
        setIsAuthenticated(true);
      } catch {
        location.href = `${location.protocol}//${location.host}?merchant_id=${merchant_id}&project_id=${project_id}&item_sku=${item_sku}&item_id=${item_id}`
      }
    }
  }, [router]);

  useEffect(() => {
    const { code } = router.query;
    if (router.isReady && code && isTokenExpired()) {
      generateJWT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const auth: UseAuth = useMemo(
    () => ({
      saveToken,
      protectRouteGuard,
      isTokenExpired,
      generateJWT,
      isAuthenticated,
      userInfo,
      resetAuth,
    }),
    [
      saveToken,
      protectRouteGuard,
      isTokenExpired,
      generateJWT,
      isAuthenticated,
      userInfo,
      resetAuth,
    ]
  );

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
