import axios from "axios";
import { ApiFunction } from ".";

const baseUrl = `${process.env.API_URL_NFT_SOLUTION}/api/v1/payments`;

const axiosInstance = axios.create({
    baseURL: baseUrl || "",
});

interface GetPaymentSettingRequest {
    project_id: number;
}

interface GetPaymentSettingResponse {
    is_non_minted_nft_purchases_allowed: boolean;
    is_paying_fees: boolean;
}

const getPaymentSetting: ApiFunction<
    GetPaymentSettingRequest,
    GetPaymentSettingResponse
> = async ({ project_id }) => {
    const path = `/projects/${project_id}/fees/settings`;

    const response = await axiosInstance.get(path);

    return response.data.data;
};

export const settingApi = {
    getPaymentSetting,
};
