import React, { createContext, useContext, useState } from "react";
import { BlockchainSymbol, getBlockchainById } from "constants/blockchains";
import isEqual from "lodash/isEqual";
import { merchantApi, pricingApi, settingApi } from "api";

export interface MintStrategy {
  id: BlockchainSymbol | string;
  label: string;
  icon: string;
  price: number;
  hasFee: boolean;
}

type UseProvideMintStrategy = {
  changeShippingMethod: (method: "mint" | "transfer") => void;
  selectMintStrategy: (strategy: MintStrategy) => void;
  selectedMintStrategy: MintStrategy | undefined;
  mintStrategies: MintStrategy[];
  shippingMethod: "mint" | "transfer" | undefined;
  loadEnabledBlockchain: (project_id: number, blockchain: string) => void;
};

const mintLater: MintStrategy = {
  id: "mintLater",
  label: "Buy Now Mint Later",
  icon: "/images/icon/paw-print.png",
  price: 0,
  hasFee: true
};

export const MintStrategyContext = createContext({} as UseProvideMintStrategy);
export const useMintStrategy = () => useContext(MintStrategyContext);

export const useProvideMintStrategy = () => {
  const [selectedMintStrategy, setSelectedMintStrategy] =
    useState<MintStrategy>();
  const [mintStrategies, setMintStrategies] = useState<MintStrategy[]>([]);
  const [shipMethod, setShipMethod] = useState<"mint" | "transfer">();

  const changeShippingMethod = (method: "mint" | "transfer") =>
    setShipMethod(method);

  const addToMintStrategy = (strategy: MintStrategy) => {
    const hasMatch = mintStrategies.some((mintStrategy) =>
      isEqual(mintStrategy, strategy)
    );

    if (!hasMatch) {
      setMintStrategies((prevStrategies) => [...prevStrategies, strategy]);
    }
  };

  const selectMintStrategy = (mintStrategy: MintStrategy) =>
    setSelectedMintStrategy(mintStrategy);

  const getPaymentSetting = (project_id: number) =>
    settingApi.getPaymentSetting({ project_id });
  const getMintingCost = () => pricingApi.getMintingCost();
  const getTransferCost = () => pricingApi.getTransferCost();
  const getEnabledBlockchain = (project_id: number) =>
    merchantApi.getEnabledBlockchain({ project_id });

  const loadEnabledBlockchain = async (project_id: number, blockchain: string) => {
    try {
      const merchantBlockchainSettings = await getEnabledBlockchain(project_id);
      const paymentSetting = await getPaymentSetting(project_id);

      const calculateCost = async () => {
        if (shipMethod === "mint") {
          return await getMintingCost();
        }

        return await getTransferCost();
      };

      const costs = await calculateCost();

      if (!merchantBlockchainSettings || !paymentSetting || !costs) {
        throw new Error("Failed to load merchant blockchain settings");
      }

      if (shipMethod === "transfer") {
        const targetBlockchain = getBlockchainById(blockchain);

        if (targetBlockchain) {
          const realPrice = costs[targetBlockchain.name];
          const priceNormalise = realPrice ? (realPrice < 1 ? 1 : realPrice) : 1;
          const mintStrategy = {
            id: targetBlockchain.id,
            label: targetBlockchain.name,
            icon: targetBlockchain.icon,
            price: priceNormalise,
            hasFee: !paymentSetting.is_paying_fees
          };
          addToMintStrategy(mintStrategy);
          setSelectedMintStrategy(mintStrategy);
        }
        return;
      }

      if (paymentSetting.is_non_minted_nft_purchases_allowed) {
        addToMintStrategy(mintLater);
        setSelectedMintStrategy(mintLater);
      }

      merchantBlockchainSettings.forEach((enabledBlockchain, index) => {
        const targetBlockchain = getBlockchainById(enabledBlockchain);

        if (targetBlockchain) {
          const realPrice = costs[targetBlockchain.name] ?? 0;

          if (!realPrice) {
            throw new Error("Failed to get minting cost");
          }

          const priceNormalise = realPrice < 1 ? 1 : realPrice;

          const mintStrategy = {
            id: targetBlockchain.id,
            label: targetBlockchain.name,
            icon: targetBlockchain.icon,
            price: priceNormalise,
            hasFee: !paymentSetting.is_paying_fees
          };

          addToMintStrategy(mintStrategy);
          if (!paymentSetting.is_non_minted_nft_purchases_allowed && index === 0) {
            setSelectedMintStrategy(mintStrategy);
          }
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  return {
    loadEnabledBlockchain,
    changeShippingMethod,
    shippingMethod: shipMethod,
    selectMintStrategy,
    selectedMintStrategy,
    mintStrategies
  };
};

export const MintStrategyProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const mintStrategy = useProvideMintStrategy();

  return (
    <MintStrategyContext.Provider value={mintStrategy}>
      {children}
    </MintStrategyContext.Provider>
  );
};
