import axios from "axios";
import qs from "qs";
import { ApiFunction } from ".";

const baseUrl = `${process.env.API_URL_LOGIN}/api`;

const axiosInstance = axios.create({
  baseURL: baseUrl || "",
});

export interface UserInfo {
  id: string;
  nickname: string | null;
  name: string | null;
  picture: unknown | null;
  tag: string;
  username: string;
  birthday: string | null;
  first_name: string | null;
  last_name: string | null;
  gender: string | null;
  email: string | null;
  is_last_email_confirmed: boolean;
  is_user_active: boolean;
  phone: string | null;
  phone_auth: unknown;
  devices: unknown[];
  groups: {
    id: number;
    name: string;
    is_default: boolean;
    is_deletable: boolean;
  }[];
  registered: string;
  external_id: string | null;
  last_login: string;
  ban: unknown;
  country: string | null;
  is_anonymous: boolean;
}

interface GenerateJWTRequest {
  grant_type: string;
  client_id: string;
  redirect_uri: string;
  code: string;
}

interface GenerateJWTResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
}

export const getToken = (): string | null | undefined => {
  if (!window) {
    return;
  }
  return localStorage.getItem("token");
};

const getUserInfo: ApiFunction<void, UserInfo> = async () => {
  const path = `/users/me`;
  const token = getToken();

  const response = await axiosInstance.get(path, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

const generateJWT: ApiFunction<
  GenerateJWTRequest,
  GenerateJWTResponse
> = async ({ grant_type, client_id, redirect_uri, code }) => {
  const path = `/oauth2/token`;
  const data = {
    grant_type,
    client_id,
    redirect_uri,
    code,
  };

  const response = await axiosInstance.post(path, qs.stringify(data), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  return response.data;
};

export const authApi = {
  getUserInfo,
  generateJWT,
};
