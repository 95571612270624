import React, { FC, ReactNode } from "react";
import SWRProvider from "./SWRProvider";
import SolWalletContextProvider from "./SolWalletProvider";
import { AuthProvider } from "../../hooks/useAuth";
import {
  WagmiConfig,
  configureChains,
  createClient,
  defaultChains,
} from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { AutoConnectProvider } from "./AutoConnectProvider";

const ContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { provider, webSocketProvider } = configureChains(defaultChains, [
    publicProvider(),
  ]);

  const client = createClient({
    provider,
    webSocketProvider,
    autoConnect: true,
  });

  return (
    <AuthProvider>
      <WagmiConfig client={client}>
        <AutoConnectProvider>
          <SolWalletContextProvider>
            <SWRProvider>{children}</SWRProvider>
          </SolWalletContextProvider>
        </AutoConnectProvider>
      </WagmiConfig>
    </AuthProvider>
  );
};

export default ContextProvider;
