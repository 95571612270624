import axios from "axios";
import { ApiFunction } from ".";
import { Blockchain } from "../configurations/blockchains";

const baseUrl = `${process.env.API_URL_NFT_SOLUTION}/api/v1/pricing`;

const axiosInstance = axios.create({
  baseURL: baseUrl || "",
});

type MintingCosts = Partial<Record<Blockchain, number>>;

const getMintingCost: ApiFunction<void, MintingCosts> = async () => {
  const path = `/minting-costs`;
  const response = await axiosInstance.get(path);
  const responseData = response.data.data;
  const costs: MintingCosts = {};

  if (responseData.solana) {
    costs[Blockchain.SOL] = responseData.solana;
  }

  if (responseData.matic) {
    costs[Blockchain.MATIC] = responseData.matic;
  }

  if (responseData.ethereum) {
    costs[Blockchain.ETH] = responseData.ethereum;
  }

  if (responseData.binance) {
    costs[Blockchain.BSC] = responseData.binance;
  }

  return costs;
};

const getTransferCost: ApiFunction<void, MintingCosts> = async () => {
  const path = `/transfer-costs`;
  const response = await axiosInstance.get(path);
  const responseData = response.data.data;
  const costs: MintingCosts = {};

  if (responseData.solana) {
    costs[Blockchain.SOL] = responseData.solana;
  }

  if (responseData.matic) {
    costs[Blockchain.MATIC] = responseData.matic;
  }

  if (responseData.ethereum) {
    costs[Blockchain.ETH] = responseData.ethereum
  }

  if (responseData.binance) {
    costs[Blockchain.BSC] = responseData.binance;
  }

  return costs;
};

export const pricingApi = {
  getMintingCost,
  getTransferCost,
};
