import axios, { AxiosError } from "axios";
import { tokenLocalStorage } from "../hooks/useAuth";

export * from "./store";
export * from "./order";
export * from "./auth";
export * from "./merchant";
export * from "./pricing";
export * from "./settings";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiFunction<Props = any, Response = unknown> = (
  props: Props
) => Promise<Response>;

export const enum APIStatus {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(tokenLocalStorage);
    }
    if (error.response.status === 404) {
      window.location.replace("/404");
    }

    if (error.code === AxiosError.ERR_BAD_RESPONSE) {
      console.log(error.response.data.error.description);
      console.log("Your request id is " + error.response.data.request_id);
    }

    if (error.response.status === "ERR_NETWORK") {
      console.error("Network seems to break down on you");
    }

    return Promise.reject(error);
  }
);
