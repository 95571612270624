import axios from "axios";
import { ApiFunction } from ".";

const baseUrl = `${process.env.API_URL_NFT_SOLUTION}/api/v1/nft`;

const axiosInstance = axios.create({
  baseURL: baseUrl || "",
});

interface GetEnabledBlockchain {
  project_id: number;
}

// type EnabledBlockchain = {
//   blockchain: string;
//   smart_contract_address: string;
//   enabled: boolean;
// };

const getEnabledBlockchain: ApiFunction<
  GetEnabledBlockchain,
  string[]
> = async ({ project_id }) => {
  // api/v1/nft/projects/:project_id/enabled-blockchains
  const path = `/projects/${project_id}/enabled-blockchains`;

  const response = await axiosInstance.get(path);

  return response.data.data;
};

export const merchantApi = {
  getEnabledBlockchain,
};
