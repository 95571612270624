import axios from "axios";
import { ApiFunction } from ".";

const baseUrl = `${process.env.API_URL_NFT_SOLUTION}/api/v1/store`;

const axiosInstance = axios.create({
  baseURL: baseUrl || "",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 500) {
      location.replace("https://babka.com/somethings-wrong");
    }

    return Promise.reject(error);
  }
);

export interface StoreItems {
  item_id: number;
  sku: string;
  name: string;
  description: string;
  image_url: string;
  price: {
    amount: string;
    amount_without_discount: string;
    currency: string;
  };
}

type lang = "en" | string;

type VirtualItemAttributeValue = {
  external_id: string;
  value: Record<lang, string>;
};

type VirtualItemAttribute = {
  external_id: string;
  name: Record<lang, string>;
  values: VirtualItemAttributeValue[];
};

type VirtualItemGroup = {
  children: unknown[];
  description: Record<lang, string>;
  external_id: string;
  id: number;
  image_url: string | null;
  is_enabled: boolean;
  level: number;
  name: Record<lang, string>;
  order: number;
  parent_external_id: string | null;
  parent_group_id: string | null;
  virtual_items_count: number;
};

type VirtualItemPrice = {
  amount: number;
  currency: string;
  is_default: true;
  is_enabled: true;
};

export interface StoreItem {
  attributes: VirtualItemAttribute[];
  description: Record<lang, string>;
  groups: VirtualItemGroup[];
  image_url: string;
  inventory_options: {
    consumable: boolean;
    expiration_period: null;
  };
  is_enabled: boolean;
  is_free: boolean;
  is_show_in_store: boolean;
  limits: {
    per_item: number | null;
    per_user: number | null;
  };
  long_description: Record<lang, string> | null;
  media_list: unknown[];
  name: Record<lang, string>;
  order: number;
  prices: VirtualItemPrice[];
  regional_prices: unknown[];
  regions: unknown[];
  sku: string;
  type: string;
  vc_prices: unknown[];
}

export type StoreUVI = {
  item_id: string;
  sku: string;
  type: "nft" | string;
  name: string;
  description: string;
  image_url: string;
  price: {
    amount: string;
    amount_without_discount: string;
    currency: string;
  };
  virtual_prices: unknown[];
  can_be_bought: boolean;
  promotions: unknown[];
  limits: {
    per_user: unknown | null;
    per_item: {
      total: number;
      available: number;
      reserved: number;
      sold: number;
    } | null;
  };
  attributes: VirtualItemAttribute[];
  is_free: boolean;
  groups: VirtualItemGroup[];
  is_minted: boolean;
  blockchain: string;
  token_id: string;
  smart_contract_address: string;
  cryptoaccount: string;
  is_sales_exist: boolean;
};

const getStoreUVI: ApiFunction<
  { project_id: number; item_sku: string },
  StoreUVI
> = async ({ project_id, item_sku }) => {
  const path = `/projects/${project_id}/items/nft/sku/${item_sku}`;

  const response = await axiosInstance.get(path);
  return response.data.data;
};

interface MarkItemAsSoldProps {
  merchant_id: number;
  project_id: number;
  item_sku: string;
}

const markItemAsSold: ApiFunction<MarkItemAsSoldProps> = async ({
  merchant_id,
  project_id,
  item_sku,
}) => {
  const path = `/items/${item_sku}/sold`;

  return await axiosInstance.post(path, {
    merchant_id,
    project_id,
  });
};

export const storeApi = {
  getStoreUVI,
  markItemAsSold,
};
