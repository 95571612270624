// Dependencies block
import React, { createContext, useContext, useState } from "react";
import { StoreUVI, storeApi } from "api";

// Data model block
type GetItem = (props: {
  projectId: number;
  itemSku: string;
}) => Promise<StoreUVI | null>;

export const enum StoreApiStatus {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}

type UseProvideNftStore = {
  storeApiStatus: StoreApiStatus;
  itemInfo: StoreUVI | undefined;
  getItem: GetItem;
};

// Context and provider build block
export const NftStoreContext = createContext({} as UseProvideNftStore);

export const useNftStore = () => useContext(NftStoreContext);

export const useProvideNftStore = (): UseProvideNftStore => {
  const [itemInfo, setItemInfo] = useState<StoreUVI>();
  const [storeApiStatus, setStoreApiStatus] = useState<StoreApiStatus>(
    StoreApiStatus.IDLE
  );

  const getItem: GetItem = async ({ projectId, itemSku }) => {
    try {
      setStoreApiStatus(StoreApiStatus.LOADING);
      const item = await storeApi.getStoreUVI({
        item_sku: itemSku,
        project_id: projectId,
      });

      setStoreApiStatus(StoreApiStatus.SUCCESS);
      setItemInfo(item);
      return item;
    } catch (error) {
      setStoreApiStatus(StoreApiStatus.ERROR);
      return null;
    }
  };

  return {
    itemInfo,
    storeApiStatus,
    getItem,
  };
};

export const NftStoreProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const nftStore = useProvideNftStore();

  return (
    <NftStoreContext.Provider value={nftStore}>
      {children}
    </NftStoreContext.Provider>
  );
};
