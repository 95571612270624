import axios, { AxiosRequestHeaders } from "axios";
import React, { FC, ReactNode } from "react";
import { SWRConfig } from "swr";

const defaultHeaders: AxiosRequestHeaders = {
  "Content-Type": "application/json",
};

export const fetcher = async (url: string, token?: string) => {
  const headers = { ...defaultHeaders };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const res = await axios.get(url, {
    headers,
  });
  return res.data;
};

const SWRProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <SWRConfig value={{ fetcher }}>{children}</SWRConfig>;
};

export default SWRProvider;
