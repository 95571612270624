import jwtDecode from "jwt-decode";

export interface JWTDecodedResult {
  email: string;
  username: string;
  xsolla_login_access_key: string;
  xsolla_login_project_id: string;
  /** User ID is being kept inside sub */
  sub: string;
  exp: number;
}

export const jwtDecoded = (token: string): JWTDecodedResult | null => {
  try {
    return jwtDecode<JWTDecodedResult>(token);
  } catch {
    return null;
  }
};
