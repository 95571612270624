import React from "react";
import type { AppProps } from "next/app";
import "../styles/globals.css";
import "@solana/wallet-adapter-react-ui/styles.css";
import { NftStoreProvider } from "hooks/useNftStore";
import ContextProvider from "../components/providers/ContextProvider";
import { MintStrategyProvider } from "hooks/useMintStrategy";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <NftStoreProvider>
      <MintStrategyProvider>
        <ContextProvider>
          <Component {...pageProps} />
        </ContextProvider>
      </MintStrategyProvider>
    </NftStoreProvider>
  );
}

export default MyApp;
