export const enum Blockchain {
  ETH = "Ethereum",
  MATIC = "Polygon",
  SOL = "Solana",
  KLAY = "Klaytn",
  FLOW = "Flow",
  XTZ = "Tezos",
  BSC = "Binance"
}

export const enum BlockchainSymbol {
  ETH = "ETH",
  MATIC = "MATIC",
  SOL = "SOL",
  KLAY = "KLAY",
  FLOW = "FLOW",
  XTZ = "XTZ",
  BSC = "BSC"
}

export const enum BlockchainCompatibility {
  EVM = "EVM",
  SOL = "SOL",
}

export const enum CryptoNetwork {
  ETH_MAINNET = 1,
  ETH_ROPSTEN = 3,
  ETH_RINKEBY = 4,
  ETH_GOERLI = 5,
  ETH_KOVAN = 42,
  MATIC_MAINNET = 137,
  MATIC_MUMBAI = 80001,
}

export interface BlockchainItem {
  id: BlockchainSymbol;
  name: Blockchain;
  icon: string;
  isActive: boolean;
  isHidden: boolean;
  compatibility: BlockchainCompatibility;
  brandColor: string;
}

export const blockchains: BlockchainItem[] = [
  {
    id: BlockchainSymbol.ETH,
    name: Blockchain.ETH,
    icon: `/images/logo/ethereum.svg`,
    isActive: true,
    isHidden: false,
    compatibility: BlockchainCompatibility.EVM,
    brandColor: "",
  },
  {
    id: BlockchainSymbol.MATIC,
    name: Blockchain.MATIC,
    icon: `/images/logo/polygon.svg`,
    isActive: true,
    isHidden: false,
    compatibility: BlockchainCompatibility.EVM,
    brandColor: "",
  },
  {
    id: BlockchainSymbol.BSC,
    name: Blockchain.BSC,
    icon: `/images/logo/binance.svg`,
    isActive: true,
    isHidden: false,
    compatibility: BlockchainCompatibility.EVM,
    brandColor: "",
  },
  {
    id: BlockchainSymbol.KLAY,
    name: Blockchain.KLAY,
    icon: `/images/logo/klaytn.svg`,
    isActive: false,
    isHidden: false,
    compatibility: BlockchainCompatibility.EVM,
    brandColor: "",
  },
  {
    id: BlockchainSymbol.FLOW,
    name: Blockchain.FLOW,
    icon: `/images/logo/flow.svg`,
    isActive: false,
    isHidden: false,
    compatibility: BlockchainCompatibility.EVM,
    brandColor: "",
  },
  {
    id: BlockchainSymbol.XTZ,
    name: Blockchain.XTZ,
    icon: `/images/logo/tezos.svg`,
    isActive: false,
    isHidden: false,
    compatibility: BlockchainCompatibility.EVM,
    brandColor: "",
  },
  {
    id: BlockchainSymbol.SOL,
    name: Blockchain.SOL,
    icon: `/images/logo/solana.svg`,
    isActive: true,
    isHidden: false,
    compatibility: BlockchainCompatibility.SOL,
    brandColor: "",
  },
];

export const isWalletCompatible = (
  blockchainId: BlockchainSymbol,
  walletCompatibilitySupports: (BlockchainCompatibility | null)[]
) => {
  const targetBlockchain = blockchains.find(
    (blockchain) => blockchain.id === blockchainId
  );

  if (!targetBlockchain) {
    throw new Error("Blockchain not found");
  }

  return walletCompatibilitySupports.includes(targetBlockchain.compatibility);
};

export const activeBlockchains = blockchains.filter(
  (blockchain) => blockchain.isActive
);

export const getBlockchainById = (id: string) =>
  blockchains.find((blockchain) => blockchain.id === id && blockchain.isActive);
